.button-bg {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#a6aac4+0,d1d1d2+0,d1d1d2+1,dfdfdf+5,eaeaea+10,f4f4f4+16,fdfdfd+29,ffffff+39,ffffff+68,fdfdfd+72,f7f7f7+80,efefef+86,e1e1e1+94,d2d2d2+100 */
  background: #a6aac4; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #a6aac4 0%,
    #d1d1d2 0%,
    #d1d1d2 1%,
    #dfdfdf 5%,
    #eaeaea 10%,
    #f4f4f4 16%,
    #fdfdfd 29%,
    #ffffff 39%,
    #ffffff 68%,
    #fdfdfd 72%,
    #f7f7f7 80%,
    #efefef 86%,
    #e1e1e1 94%,
    #d2d2d2 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #a6aac4 0%,
    #d1d1d2 0%,
    #d1d1d2 1%,
    #dfdfdf 5%,
    #eaeaea 10%,
    #f4f4f4 16%,
    #fdfdfd 29%,
    #ffffff 39%,
    #ffffff 68%,
    #fdfdfd 72%,
    #f7f7f7 80%,
    #efefef 86%,
    #e1e1e1 94%,
    #d2d2d2 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #a6aac4 0%,
    #d1d1d2 0%,
    #d1d1d2 1%,
    #dfdfdf 5%,
    #eaeaea 10%,
    #f4f4f4 16%,
    #fdfdfd 29%,
    #ffffff 39%,
    #ffffff 68%,
    #fdfdfd 72%,
    #f7f7f7 80%,
    #efefef 86%,
    #e1e1e1 94%,
    #d2d2d2 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6aac4', endColorstr='#d2d2d2',GradientType=0 ); /* IE6-9 */
}
