@import "react-vis/dist/style";
@import "./chart";
@import "./softbank";

:root {
  --color-oppo-blue-royal: #2d6cd4;
  --color-oppo-blue-dark-royal: #010049;
  --color-oppo-blue-light-steal: #cbd5e8;
  --color-oppo-blue-midnight: #222f77;
  --color-oppo-goldenrod-dark: #93ba14;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
html {
  height: 100%;
}
body {
  background-color: var(--color-oppo-blue-midnight);
  background-image: url("/assets/layout/bg.png");
  height: 100%;
  -ms-overflow-style: scrollbar;
  overflow-y: scroll;
  overscroll-behavior-y: none;
}
@screen md {
  body {
    background-image: url("/assets/layout/bg_for_pj.png");
  }
}
